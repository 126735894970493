import {ReactElement, useEffect, useState} from "react";
import Menu from "../../components/Menu";
import {fetchAccommodations, HotelData} from "../../api/accommodations";
import {useUser} from "../../auth/useUser";


type AccommodationProps = {}

function Accommodation(props: AccommodationProps): ReactElement {
  const user = useUser()
  const [hotels, setHotels] = useState<HotelData[]>()

  useEffect(() => {
    fetchAccommodations(user.user)
      .then(result => {
        setHotels(result.filter(h => h.url.length > 0))
      })
      .catch(e => {
        console.log("Failed loading accommodations")
      })
  }, [user.user]);

  return (
    <div>
      <Menu/>
      <div className={"container mx-auto px-8 my-16"}>
        <div className={"text-center mb-16 mx-8"}>
          <h1 className={"text-3xl"}>Unterkunft</h1>
          <div className={"my-8"}>
            Wir haben in den folgenden Hotels für euch Zimmer reserviert. <br/>Bei Interesse gebt uns bitte rechtzeitig
            Bescheid.
          </div>
          <div>
            {
              hotels?.map(hotel =>
                <div>
                  <h2>{hotel.name}</h2>
                  <a href={hotel.url}>
                    <img className={"w-80 h-52 object-cover"} src={hotel.img} alt={hotel.name}/>
                  </a>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>)
}

export default Accommodation